import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./components/hris/employee/dashbaord/dashboard.jsx";
import Reset_Pw from "./components/hris/employee/dashbaord/reset_password.jsx";
import User_Profile from "./components/hris/employee/dashbaord/user_profile.jsx";
import Emp_Dashboard from "./components/hris/emp_management/dashboard/dashboard.jsx";
import Login from "./components/hris/login/login.jsx";
import useAuth from "./hooks/useAuth";

const AppContent = () => {
  useAuth(); // Apply auth hook globally

  return (
    <div>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Login />} />

        {/* Protected routes */}
        <Route path="/home" element={<Home />} />
        <Route path="/reset-pw" element={<Reset_Pw />} />
        <Route path="/user-profile" element={<User_Profile />} />
        <Route path="/emp-dashboard" element={<Emp_Dashboard />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
