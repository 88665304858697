import React, { useState, useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css"; // Import default styles

// Function to get cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

const LeaveRequestUser = ({ onClose, leaveCounts }) => {
  // Accept leaveCounts as a prop
  const [restrictedDates, setRestrictedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [reason, setReason] = useState("");
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    const fetchRestrictedDates = async () => {
      try {
        const response = await fetch(
          "https://backbritish.talentfort.live/v1/hris/leave/getRestrictedDate"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setRestrictedDates(data);
      } catch (error) {
        console.error("Error fetching restricted dates:", error);
      }
    };

    fetchRestrictedDates();
  }, []);

  const handleDateClick = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const restricted = restrictedDates.find(
      (d) => d.restricted_dates === formattedDate
    );

    if (restricted) {
      setPopupMessage(
        `The selected date (${formattedDate}) is restricted due to: ${restricted.reason_for_restriction}`
      );
      setShowPopup(true);
    } else {
      // Ensure that the same date is not selected multiple times
      if (!selectedDates.includes(formattedDate)) {
        setSelectedDates([...selectedDates, formattedDate]);
        setLeaveDetails([
          ...leaveDetails,
          { date: formattedDate, day_part: "", leave_category: "" },
        ]);
      }
    }
  };

  const handleLeaveDetailChange = (index, field, value) => {
    const newLeaveDetails = [...leaveDetails];
    newLeaveDetails[index][field] = value;
    setLeaveDetails(newLeaveDetails);
  };

  const handleRemoveDate = (index) => {
    const newSelectedDates = [...selectedDates];
    const newLeaveDetails = [...leaveDetails];
    newSelectedDates.splice(index, 1);
    newLeaveDetails.splice(index, 1);
    setSelectedDates(newSelectedDates);
    setLeaveDetails(newLeaveDetails);
  };

  const handleSubmit = async () => {
    try {
      const employee_no = getCookie("employee_no");
      const payload = {
        employee_no,
        reason,
        requested_dates: leaveDetails.map((detail) => ({
          request_day: detail.date,
          day_part: detail.day_part,
          leave_category: detail.leave_category,
        })),
      };

      console.log("Submitting leave request with payload:", payload); // Debugging

      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/leave/addleave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        // Log the response for more details
        const errorResponse = await response.text();
        console.error("Error response:", errorResponse);
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Leave request submitted:", result);

      if (result.success) {
        onClose(); // Close the modal on success
      } else {
        console.error("Leave request failed:", result);
      }
    } catch (error) {
      console.error("Error submitting leave request:", error);
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const isRestricted = restrictedDates.some(
        (d) => d.restricted_dates === formattedDate
      );
      return isRestricted ? "restricted-date" : "";
    }
    return "";
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const restricted = restrictedDates.find(
        (d) => d.restricted_dates === formattedDate
      );
      if (restricted) {
        return (
          <div title={`Restricted: ${restricted.reason_for_restriction}`}>
            {/* Adding a red border to indicate restriction */}
            <div className="w-full h-full border-2 border-red-500 rounded"></div>
          </div>
        );
      }
    }
    return null;
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  // Define the leave types with keys matching leaveCounts
  const leaveTypes = [
    { value: "annual", label: "Annual" },
    { value: "casual", label: "Casual" },
    { value: "medical", label: "Medical" },
    { value: "special", label: "Special" },
    { value: "noPay", label: "No Pay" },
  ];

  // Filter leave types based on current counts
  const availableLeaveTypes = leaveTypes.filter((type) => {
    // Ensure leaveCounts and the specific type exist
    return leaveCounts[type.value] && leaveCounts[type.value].current > 0;
  });

  return (
    <div className="bg-white p-5 rounded-lg w-[600px] h-[800px] relative overflow-y-auto">
      <div className="flex justify-between items-center mb-5">
        <p className="text-[25px] font-semibold">Request for Leave</p>
        <IoIosCloseCircle
          className="w-8 h-8 cursor-pointer"
          onClick={onClose}
        />
      </div>

      <div className="mb-5">
        <Calendar
          onClickDay={handleDateClick}
          tileClassName={tileClassName}
          tileContent={tileContent}
        />
      </div>

      {selectedDates.map((date, index) => (
        <div key={index} className="border p-4 rounded-lg mb-3 relative">
          <p className="font-semibold">Date: {date}</p>
          <button
            type="button"
            className="absolute top-0 right-0 text-red-500 p-2"
            onClick={() => handleRemoveDate(index)}
          >
            <FaTrashAlt />
          </button>
          <div className="mt-2">
            <label className="block text-sm font-medium">Leave Category</label>
            <select
              value={leaveDetails[index].leave_category}
              onChange={(e) =>
                handleLeaveDetailChange(index, "leave_category", e.target.value)
              }
              className="border border-[#8764A0] rounded-lg h-10 w-full"
            >
              <option value="">Select Category</option>
              {availableLeaveTypes.length > 0 ? (
                availableLeaveTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No Available Leave Types
                </option>
              )}
            </select>
          </div>
          {leaveDetails[index].leave_category && (
            <div className="mt-2">
              <label className="block text-sm font-medium">Day Part</label>
              <select
                value={leaveDetails[index].day_part}
                onChange={(e) =>
                  handleLeaveDetailChange(index, "day_part", e.target.value)
                }
                className="border border-[#8764A0] rounded-lg h-10 w-full"
              >
                <option value="">Select Day Part</option>
                <option value="Full Day">Full Day</option>
                <option value="1st Half Day">1st Half Day</option>
                <option value="2nd Half Day">2nd Half Day</option>
              </select>
            </div>
          )}
        </div>
      ))}

      <div className="mt-5">
        <label className="block text-sm font-medium">Reason for Leave</label>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="border border-[#8764A0] rounded-lg h-[100px] w-full"
        />
      </div>

      <div className="flex justify-end mt-5">
        <button
          type="button"
          onClick={handleSubmit}
          className="bg-[#8764A0] text-white p-3 rounded-lg"
        >
          Submit Leave Request
        </button>
      </div>

      {/* Popup for restricted dates */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-lg w-[400px]">
            <p className="text-lg font-semibold mb-4">{popupMessage}</p>
            <button
              onClick={closePopup}
              className="bg-[#8764A0] text-white p-2 rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveRequestUser;
