import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Emp_barchart = ({data}) => {

  const chartData = [
    { name: "Total Workforce", value: data.totalWorkForce, fill: '#FF00FF' },
    { name: "Present Workforce", value: data.presentWorkForce, fill: '#FFFF99' },
    { name: "Absent Workforce", value: data.absentWorkForce, fill: '#99FF99' },
    { name: "Late Arrivals", value: data.lateArrivals, fill: '#FF9933' },
    { name: "In Leave", value: data.inLeave, fill: '#CC99FF' },
  ];

  return (
      <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={chartData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Emp_barchart;
