import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import { FaBagShopping } from "react-icons/fa6";
import Navbar from "../../navbar/navbar.jsx";
import { PiOfficeChair } from "react-icons/pi";
import { PiSealWarningBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { TbBeach } from "react-icons/tb";

import Emp_Chart from "./emp_Chart.jsx";
import Leave_Details from "./leave_details.jsx";
import Emp_barchart from "./emp_barchart.jsx";

// Function to get cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const Dashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [data, setData] = useState({});

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employee_no = getCookie("employee_no"); // Get employee_no from cookies
        const response = await fetch(
          `<<url>>/v1/hris/employees/getAttendanceStats?employee_no=${employee_no}`
        );
        const result = await response.json();
        console.log("data => ", result);

        if (result.success) {
          setData({
            totalWorkforce: result.data.totalWorkforce,
            presentWorkforce: result.data.presentWorkforce,
            absentWorkforce: result.data.absentWorkforce,
            lateArrivals: result.data.lateArrivals,
            inLeave: result.data.inLeave,
          });
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mx-10 mt-5">
      {/* top layer */}
      <Navbar />

      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Dashboard - Employee Information Management
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <FaBagShopping className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Workforce</p>
            <p className="text-[30px] font-semibold">{data.totalWorkforce}</p>
          </div>
          {/* card 2 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiOfficeChair className="w-8 h-8" />
              </div>

              <div>
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Present Workforce</p>
            <p className="text-[30px] font-semibold">{data.presentWorkforce}</p>
          </div>
          {/* card 3 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiSealWarningBold className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Absent Workforce</p>
            <p className="text-[30px] font-semibold">{data.absentWorkforce}</p>
          </div>
          {/* card 4 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <LuAlarmClock className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Late Arrivals</p>
            <p className="text-[30px] font-semibold">{data.lateArrivals}</p>
          </div>

          {/* card 5 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <TbBeach className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">In Leave</p>
            <p className="text-[30px] font-semibold">{data.inLeave}</p>
          </div>
        </div>
      </div>
      {/* lower layer */}

      <div className="flex gap-3 items-center w-full">
        {/* section1 */}
        <div className="flex-auto">
          <Leave_Details data={data} />
        </div>

        {/* section2 */}
        <div className="flex-auto">
          <Emp_Chart data={data} />
        </div>

        <div className="flex-auto mt-10">
          <Emp_barchart data={data} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
